import React, { memo, useState } from "react";
import styles from "./index.module.scss";
import axios from "axios";
import Alert from "react-bootstrap/Alert";

const Hero = () => {
    const [value, setValue] = useState("");
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [showEmailAddedMessage, setShowEmailAddedMessage] = useState(false);
    const [showApiErrorMessage, setShowApiErrorMessage] = useState(false);
    const [apierrorMessage, setApiErrorMessage] = useState("");

    const submitForm = async event => {
        event.preventDefault();
        try {
            // eslint-disable-next-line no-useless-escape
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value)) {
                await axios.get(`https://api.bullieverse.com/beyond/addToWhiteList?email=${value}`);
                setShowEmailAddedMessage(true);
                setShowApiErrorMessage(false);
                setTimeout(() => {
                    setShowEmailAddedMessage(false);
                }, 3000);
            } else {
                setIsInvalidEmail(true);
            }
        } catch (error) {
            setShowApiErrorMessage(true);
            console.error(error);
            setApiErrorMessage(error?.response?.data?.message);
        }
    }

    return (
        <div className={styles.bg}>
            {showEmailAddedMessage && (
                <Alert variant="success" className="alert" dismissible>
                    You have successfully added your email for the waitlist.
                </Alert>
            )}

            {showApiErrorMessage && (
                <Alert variant="danger" className="alert" dismissible>
                    {apierrorMessage}
                </Alert>
            )}

            <div className={`${styles.main} container`}>
                <div className={styles.tag}>
                    Web3 X Gaming
                </div>
                <h1>
                    We are Building the Future of Gaming
                </h1>
                <p>
                    Pushing the boundaries of Web3 gaming, where creativity meets unparalleled fun.
                </p>
                <form className={styles.subscribeBox} onSubmit={(e) => submitForm(e)}>
                    <input
                        type="email"
                        value={value}
                        placeholder="Type your email address"
                        className={styles.inputBox}
                        onChange={(e) => {
                            setValue(e.target.value);
                            setIsInvalidEmail(false);
                        }}
                    ></input>
                    <button type="submit" disabled={value === "" ? true : false}>Join Waitlist</button>
                </form>
                {isInvalidEmail && <span className={styles.error}>Please enter a valid email address.</span>}
            </div>
        </div >
    )
}

export default memo(Hero)