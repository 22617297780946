import React, { memo } from "react";
import styles from "./index.module.scss";

const NavBar = () => {
    return (
        <div className={`${styles.main} container`}>
            <div className={styles.logo}>
                <img src="/assets/navbar/logo.svg" alt="logo" />
            </div>
            <div className={styles.right}>
                <a href="https://twitter.com/beyondgames_xyz" target="_blank" rel="noreferrer">
                    <img src="/assets/navbar/x.svg" alt="logo" />
                </a>
                {/* <button>White Paper</button> */}
            </div>
        </div>
    )
}

export default memo(NavBar)