// import Beyond from "components/Beyond";
// import BeyondChain from "components/BeyondChain";
// import BeyondStudio from "components/BeyondStudio";
// import BeyondToken from "components/BeyondToken";
// import BeyondforCreators from "components/BeyondforCreators";
// import BeyondforGamers from "components/BeyondforGamers";
// import BeyondinIndia from "components/BeyondinIndia";
// import Ecosystem from "components/Ecosystem";
import Footer from "components/Footer";
import Hero from "components/Hero";
import NavBar from "components/NavBar";
//import Quandary from "components/Quandary";

function App() {
  return (
    <>
      <NavBar />
      <Hero />
     {/* <Quandary /> */}
     {/* <Ecosystem /> */}
     {/* <BeyondChain /> */}
     {/* <BeyondforCreators /> */}
     {/* <BeyondStudio /> */}
     {/* <BeyondforGamers /> */}
     {/* <BeyondinIndia /> */}
      {/* <BeyondToken /> */}
      {/* <Beyond /> */}
      <Footer />
    </>
  );
}

export default App;
