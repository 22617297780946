import React, { memo } from "react";
import styles from "./index.module.scss";

const Footer = () => {
    return (
        <div className={styles.main}>
            <div className={`${styles.flexBox} container`}>
                <div className={styles.leftSection}>
                    <img src="/assets/footer/logo.svg" alt="logo" className={styles.logo} />
                    <img src="/assets/footer/beyond.svg" alt="logo" className={styles.beyond} />
                    Pushing the boundaries of Web3 gaming, where creativity meets unparalleled fun.
                </div>
                <div className={styles.rightSection}>
                    {/* <button>White Paper</button> */}
                    <span>
                        <a href="https://twitter.com/beyondgames_xyz" target="_blank" rel="noreferrer">
                            <img src="/assets/footer/x.svg" alt="logo" className={styles.logo} />
                            Follow us on Twitter</a>
                    </span>
                    contact@beyondgaming.xyz
                </div>
            </div>
            <div className={styles.copyrights}>
                © 2023 Beyond. All rights reserved
            </div>
        </div>
    )
}

export default memo(Footer)